import {Typography} from '@hconnect/uikit'
import {Box, Switch} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {
  removeOpenInvoicesFilter,
  selectOpenInvoicesFilter,
  setOpenInvoicesFilter
} from '../Invoice.filters'
import {InvoiceStatus} from '../Invoice.types'

export const OpenInvoicesFilter: React.FC = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const includesOpenInvoices = useSelector<
    AppState,
    {status: keyof typeof InvoiceStatus} | undefined
  >((state) => selectOpenInvoicesFilter(state))

  const toggleOpenInvoicesFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    checked
      ? dispatch(setOpenInvoicesFilter({status: [InvoiceStatus.open]}))
      : dispatch(removeOpenInvoicesFilter())
  }

  return (
    <Box display="flex" pl={1} mr={1} alignItems="center">
      <Switch
        data-test-id="invoices-open-filter"
        aria-label="Open invoice filter"
        size="small"
        checked={includesOpenInvoices && includesOpenInvoices.status.includes(InvoiceStatus.open)}
        onChange={toggleOpenInvoicesFilter}
      />
      <Box m={1} />
      <Typography variant="body1" color="textPrimary">
        {t('invoice.openInvoicesFilterLabel')}
      </Typography>
    </Box>
  )
}
