import {Typography} from '@hconnect/uikit'
import {Box, Switch} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {
  removeSurchargesOnlyFilter,
  selectSurchargesOnlyFilter,
  setSurchargesOnlyFilter
} from '../Invoice.filters'

interface Props {}
export const SurchargesOnlyFilter: React.FC<Props> = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const includesServicesOrSurcharges = useSelector<AppState, boolean>((state) =>
    selectSurchargesOnlyFilter(state)
  )

  const toggleSurchargesOrServicesOnly = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    checked
      ? dispatch(setSurchargesOnlyFilter({includesServicesOrSurcharges: true}))
      : dispatch(removeSurchargesOnlyFilter())
  }

  return (
    <Box display="flex" pl={1} alignItems="center">
      <Switch
        data-test-id="invoices-servicesOrSurcharges-filter"
        aria-label="Surcharges and services switch"
        size="small"
        checked={includesServicesOrSurcharges}
        onChange={toggleSurchargesOrServicesOnly}
      />
      <Box m={1} />
      <Typography variant="body1" color="textPrimary">
        {t('invoice.servicesOrSurcharges.filterLabel')}
      </Typography>
    </Box>
  )
}
