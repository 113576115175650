import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LookupFilter as Lookup} from '@hconnect/uikit'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {useFeaturesState} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {InvoiceView, LookupSelectItem, LookupSelectItems} from '../Action.types'
import {switchView} from '../Invoice.action'
import {removeLookupFilter, selectLookupFilter, setLookupFilter} from '../Invoice.filters'

const DEFAULT_LOOKUP_KEY = LookupSelectItem.INVOICE

interface LookupFilterType {
  hasError: boolean | undefined
  setFilter: (props: any) => void
  filter: any
  customerId: string
  switchToLookupView: () => void
  switchToDefaultView: () => void
  removeFilter: () => void
  payerId?: string
  analyticsId?: string
  userId?: string
}
const LookupFilter: React.FC<LookupFilterType> = ({
  hasError,
  setFilter,
  filter = {},
  switchToLookupView,
  switchToDefaultView,
  removeFilter,
  customerId,
  payerId,
  analyticsId,
  userId
}) => {
  const {t} = useTranslation()
  const {getFeature} = useFeaturesState()

  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const isSearchByCustomerDeliveryNumber = getFeature('SearchByCustomerDeliveryNumber')
  const purchaseOrderFilter = getFeature('PurchaseOrderFilter')

  const selectItems = [
    {key: LookupSelectItem.INVOICE, value: t('invoice.lookup.invoiceNo')},
    ...(!isCustomerDeliveryNumber
      ? [{key: LookupSelectItem.TICKET, value: t('invoice.lookup.ticketNo')}]
      : []),
    ...(isCustomerDeliveryNumber
      ? [{key: LookupSelectItem.BOL, value: t('invoice.lookup.ticketNo')}]
      : []),
    ...(purchaseOrderFilter ? [{key: LookupSelectItem.PO, value: t('invoice.lookup.poNo')}] : [])
  ]

  const inputLabels = {
    [LookupSelectItem.INVOICE]: t('invoice.lookup.labelinvoiceNo'),
    [LookupSelectItem.TICKET]: t('invoice.lookup.labelticketNo'),
    [LookupSelectItem.BOL]: t('invoice.lookup.labelticketNo'),
    [LookupSelectItem.PO]: t('invoice.lookup.labelpoNo')
  }

  const placeholderLabels = {
    [LookupSelectItem.INVOICE]: t('invoice.lookup.invoicePlchld'),
    [LookupSelectItem.TICKET]: t('invoice.lookup.ticketPlchld'),
    [LookupSelectItem.BOL]: t('invoice.lookup.ticketPlchld'),
    [LookupSelectItem.PO]: t('invoice.lookup.poPlchld')
  }

  // find selected filter from redux store or fallback to the first item in list
  const initialSelectedItem =
    selectItems.find((item) => item.key === filter.entity) || selectItems[0]

  const [placeholder, setPlaceholder] = useState(placeholderLabels[DEFAULT_LOOKUP_KEY])
  const [lookupEntity, setLookupEntity] = useState<LookupSelectItems | undefined>(
    initialSelectedItem.key || ''
  )

  const [lookupInputLabel, setInputLookupLabel] = useState<string>(
    inputLabels[initialSelectedItem.key] || ''
  )
  const [lookupLabel, setLookupLabel] = useState<string>(initialSelectedItem.value || '')
  const [lookupInput, setLookupInput] = useState(filter.value || '')

  useEffect(() => {
    setLookupInput(filter.value || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.value, t])

  useEffect(() => {
    const value = (lookupInput || '').trim()
    if (!value) {
      handleReset()
    }
  }, [lookupInput])

  useEffect(() => {
    setInputLookupLabel(inputLabels[initialSelectedItem.key])
    setLookupLabel(initialSelectedItem.value)
  }, [t])

  useEffect(() => {
    handleReset()
  }, [customerId, payerId])

  const handleLookupSelectChange = ({key, value}: {key: string; value: string}) => {
    trackEvent('hubLookupSelect', {
      lookupCategory: key,
      lookupSource: 'invoices',
      customerId,
      userId,
      analyticsId
    })
    setPlaceholder(placeholderLabels[key])
    setInputLookupLabel(inputLabels[key])
    setLookupLabel(value)
    setLookupEntity(key as LookupSelectItems)
  }

  const handleReset = () => {
    setLookupInput('')
    switchToDefaultView()
    removeFilter()
  }

  const handleLookup = () => {
    const value = (lookupInput || '').trim()
    if (value && lookupEntity) {
      trackEvent('hubLookupExecute', {
        lookupCategory: lookupEntity,
        lookupValue: value,
        lookupSource: 'invoices',
        customerId,
        userId,
        analyticsId
      })
      switchToLookupView()
      if (
        isSearchByCustomerDeliveryNumber &&
        value.length === 10 &&
        lookupEntity === LookupSelectItem.BOL
      ) {
        setFilter({
          entity: LookupSelectItem.TICKET,
          label: lookupLabel,
          value
        })
      } else {
        setFilter({
          entity: lookupEntity,
          label: lookupLabel,
          value
        })
      }
    }
  }

  return (
    <Lookup
      hasError={hasError}
      data-test-id="finance-lookup"
      placeholder={placeholder}
      label={lookupInputLabel}
      inputValue={lookupInput}
      onLookup={handleLookup}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setLookupInput(event.target.value)
      }}
      onSelectChange={handleLookupSelectChange}
      selectedKey={lookupEntity}
      onSelectRenderValue={() => <div style={{marginLeft: 31}}>{lookupLabel}</div>}
      onReset={handleReset}
      selectItems={selectItems}
      {...{style: {height: '100%'}}}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectLookupFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => dispatch(setLookupFilter(props)),
  switchToLookupView: () => dispatch(switchView({view: InvoiceView.LOOKUP})),
  switchToDefaultView: () =>
    dispatch(
      switchView({
        view: InvoiceView.DEFAULT,
        clearPrevView: true
      })
    ),
  removeFilter: () => dispatch(removeLookupFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(LookupFilter)
