import {
  dateRangeFormatter,
  ErrorText,
  lastThirtyDays,
  MOMENTJS_DATE_FORMAT,
  ResponsiveTable,
  Typography,
  useBreakPoints
} from '@hconnect/uikit'
import {Paper} from '@material-ui/core'
import {Box} from '@mui/material'
import {useQueryClient} from '@tanstack/react-query'
import flatMap from 'lodash/flatMap'
import values from 'lodash/values'
import moment, {Moment} from 'moment'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch} from 'redux'
import {makeStyles} from 'tss-react/mui'

import {errorKeyFromRequestError} from '../../../ErrorMap'
import {useQueryParamState} from '../../../Hooks/useQueryParamState'
import {LoadMore} from '../../../Molecules/LoadMore'
import {ResultsForMessage} from '../../../Molecules/ResultsForMessage'
import {SMFilter} from '../../../Molecules/SMFilter/SMFilter'
import {
  selectCustomers,
  CustomerStateType,
  setGlobalCustomerFilter
} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {useFeaturesState} from '../../../Organisms/Features'
import {
  Payer,
  PayersStateType,
  selectCustomerFromPayer,
  selectPayers,
  setGlobalPayerFilter
} from '../../../Organisms/Payers'
import {AppState} from '../../../Root.store'
import {getErrorMessage} from '../../../util/action.helper'

import {PaymentsCardList} from './components'
import {Filters, PaymentsFilter} from './Filters'
import {DefaultLimit, usePayments} from './hooks'
import {PaymentsColumns} from './Payments.columns'
import {useSupplierNamesFromCustomer} from '../../../Molecules/SupplierFilter/useGetSupplierName' 

export const useStyles = makeStyles()(() => ({
  gridItem: {
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }
}))

const setPayerAndCustomerFromQuery = (
  customers: Customer[],
  dispatch: Dispatch<any>,
  entities?: Record<string, Payer[]>,
  payerId?: string
) => {
  if (entities && payerId) {
    const possiblePayer = flatMap(values(entities)).find((payer) => payer.payerId === payerId)
    if (possiblePayer) {
      const customer = selectCustomerFromPayer(possiblePayer, customers)
      if (customer) {
        dispatch(setGlobalCustomerFilter(customer))
        dispatch(setGlobalPayerFilter(possiblePayer))
      }
    }
  }
}
interface Type {
  analytics: {
    userId: string | undefined
    userMail: string | null | undefined
    userCountry: string | null | undefined
    userRoles: any[] | undefined
    userIsInternal: boolean | undefined
    customerId: string | undefined
    customerName: string | undefined
    filteredDateRange?: {
      startDate: any
      endDate: any
    }
  }
}
// eslint-disable-next-line complexity
export const PaymentsComponentPage: React.FC<Type> = ({analytics}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {getFeature} = useFeaturesState()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const screenSize = useBreakPoints()
  const [smFilterVisible, setSmFilterVisible] = React.useState<boolean>(false)
  const customersState = useSelector<AppState, CustomerStateType>((state: AppState) =>
    selectCustomers(state)
  )

  const {classes} = useStyles()
  const {isFetching: isCustomersFetching, customers} = customersState
  const payersState = useSelector<AppState, PayersStateType>((state) => selectPayers(state))
  const {selectedPayer, entities, isFetching: isPayersFetching} = payersState
  const [startDate, endDate] = lastThirtyDays(language)
  const [filter, setFilter] = useQueryParamState<PaymentsFilter>(
    {
      startDate: startDate && startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: endDate && endDate.format(MOMENTJS_DATE_FORMAT),
      payerId: undefined,
      skip: 0,
      limit: DefaultLimit,
      sortedByKey: 'postingDate',
      sortedByDirection: 'desc'
    },
    true
  )

  const {
    error: fetchingError,
    data: paymentData,
    isFetching
  } = usePayments(
    selectedPayer?.payerId,
    filter.startDate,
    filter.endDate,
    +filter.limit,
    `${filter.sortedByKey} ${filter.sortedByDirection}`,
    true
  )
  const {total, payerPayments} = paymentData || {}

  const {
    data: supplierNames,
    isLoading: supplierNamesIsLoading
  } = useSupplierNamesFromCustomer(analytics.customerId || '')

  const loading = isFetching || isPayersFetching || isCustomersFetching || supplierNamesIsLoading
  const error =
    fetchingError && selectedPayer?.payerId
      ? getErrorMessage(
          'payments',
          errorKeyFromRequestError(fetchingError),
          null,
          () =>
            void queryClient.invalidateQueries([
              'payments',
              {
                payerId: selectedPayer.payerId,
                startDate: filter.startDate,
                endDate: filter.endDate,
                sortedBy: `${filter.sortedByKey} ${filter.sortedByDirection}`,
                limit: filter.limit
              }
            ]),
          null
        )
      : null
  const items = selectedPayer && payerPayments ? payerPayments : []

  const getEmptyMessage = (isLoading: boolean) => (isLoading ? t('loading') : t('noData'))

  const surchargeEnabled = getFeature('PaymentSurchargeRates')
  const showCompanyCode = getFeature('ShowSupplierFilter')
  const columnConfig = useMemo(
    () => PaymentsColumns({t, language, surchargeEnabled, showCompanyCode, supplierNames}),
    [t, language, supplierNames]
  )

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }
  const resultsForMessage = [
    selectedPayer?.payerName,
    dateRangeFormatter(startDate, endDate, language)
  ]

  useEffect(() => {
    setPayerAndCustomerFromQuery(customers, dispatch, entities, filter?.payerId)
  }, [filter.payerId, entities])

  useEffect(() => {
    setFilter({
      ...filter,
      payerId: selectedPayer?.payerId,
      limit: DefaultLimit
    })
  }, [selectedPayer, filter.startDate, filter.endDate])

  const handleDateChange = (dates: {startDate: Moment | null; endDate: Moment | null}) => {
    setFilter({
      ...filter,
      startDate: dates.startDate?.format(MOMENTJS_DATE_FORMAT) || filter.startDate,
      endDate: dates.endDate?.format(MOMENTJS_DATE_FORMAT) || filter.endDate
    })
  }

  useEffect(() => {
    setFilter({
      ...filter,
      startDate: filter.startDate || startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: filter.endDate || endDate.format(MOMENTJS_DATE_FORMAT)
    })
  }, [])

  useEffect(() => {
    queryClient.invalidateQueries([
      'payments',
      {
        payerId: selectedPayer?.payerId || '',
        startDate: filter.startDate,
        endDate: filter.endDate,
        sortedBy: `${filter.sortedByKey} ${filter.sortedByDirection}`,
        limit: filter.limit
      }
    ])
  }, [])

  const showLoadMore = total && items && items.length ? total > items.length : false

  const loadMore = () =>
    setFilter({
      ...filter,
      limit: Number(filter.limit) + 10
    })

  const smallScreen = ['sm', 'xs'].includes(screenSize)

  return (
    <Paper
      elevation={4}
      aria-label="Payment list"
      className={smallScreen ? '' : classes.gridItem}
      style={{paddingBottom: 20, ...(!smallScreen ? {height: '100%'} : {})}}
    >
      {!smallScreen ? (
        <Filters
          startDate={moment(filter.startDate)}
          endDate={moment(filter.endDate)}
          handleDates={handleDateChange}
        />
      ) : (
        <SMFilter
          filters={[
            <Filters
              key="all-payments-fiters"
              startDate={moment(filter.startDate)}
              endDate={moment(filter.endDate)}
              handleDates={handleDateChange}
              xs
            />
          ]}
          isVisible={smFilterVisible}
          setIsVisible={setSmFilterVisible}
          numberOfItems={items?.length}
          apply={() => setSmFilterVisible(false)}
        />
      )}

      {error ? (
        <ErrorText
          data-test-id="payments-error-message"
          label={t(error.translationKey)}
          showRetry={!!error?.callback}
          onRetry={error.callback}
        />
      ) : null}
      {!error ? (
        <>
          {!loading && !smallScreen ? <ResultsForMessage messageList={resultsForMessage} /> : null}
          {!smallScreen ? (
            <Box px={2}>
              <ResponsiveTable
                rows={items}
                columns={columnConfig}
                onSort={(e, sortKey) => {
                  setSortKey(sortKey)
                }}
                sortedBy={filter.sortedByKey}
                sortingOrder={filter.sortedByDirection}
                loading={loading}
                keyField="paymentId"
                emptyMessage={
                  <Typography variant="caption">{getEmptyMessage(isFetching)}</Typography>
                }
              />
            </Box>
          ) : (
            <Box mx={2}>
              <PaymentsCardList
                rows={items || []}
                loading={loading}
                surchargeEnabled={surchargeEnabled}
                supplierNames={supplierNames}
                keyField="paymentId"
                screenSize={screenSize}
                fullScreen={smallScreen}
                analytics={analytics}
              />
            </Box>
          )}

          {showLoadMore && (
            <LoadMore
              isFetching={isFetching}
              skip={filter.skip}
              onLoadMoreClick={loadMore}
              onLight
            />
          )}
        </>
      ) : null}
    </Paper>
  )
}
