import {Box} from '@material-ui/core'
import React from 'react'
import {useSelector} from 'react-redux'

import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../../Organisms/Customers/CustomerSimpleLookup'
import {Features, useFeaturesState} from '../../../../Organisms/Features'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import {CustomersPayers, selectCustomersPayers} from '../../../../Organisms/Payers'
import ProjectsSitesFilter from '../../../../Organisms/Projects/ProjectsSitesFilter'
import {Site, SitesFilter} from '../../../../Organisms/SitesTypeahead'
import {usePermissions} from '../../../../Permissions'
import {AppState} from '../../../../Root.store'

import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import {OpenInvoicesFilter} from './OpenInvoicesFilter'
import PaymentStatusFilter from './PaymentStatusFilter'
import {SurchargesOnlyFilter} from './SurchargesOnlyFilter'
import SupplierNameFilterInvoices from '../../../../Molecules/SupplierFilter/SupplierFilterInvoice'

export interface FilterBarErrorInvoice {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorInvoice[]
  customerId: string
  payerId?: string
  site?: Site
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
  xs?: boolean
  lookupAnalyticsId?: string
  userId?: string
}

const Filters: React.FC<Props> = ({
  customerId,
  payerId,
  site,
  setSite,
  xs = false,
  lookupAnalyticsId,
  userId,
  ...props
}) => {
  const {getFeature} = useFeaturesState()
  const {displayCustomerSimpleLookup} = usePermissions()
  const {errors} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')
  const customerSearchByNameEnabled = getFeature('CustomersByName')
  const customerPayers: CustomersPayers[] = useSelector<AppState, CustomersPayers[]>((state) =>
    selectCustomersPayers(state)
  )
  if (xs) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" mb={1} width="100%">
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="orders-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
            />
          ) : (
            <CustomersFilter onLight customersOnly />
          )}
        </Box>
        <DateFilter />
        <Box my={1} />
        <Features name="DestinationSearchByName" resolution="disabled">
          <Features name="ProjectSitesFilter">
            <ProjectsSitesFilter onLight />
            <Box my={1} />
          </Features>
        </Features>
        <Features name="DestinationSearchByName">
          <SitesFilter customerId={customerId} limit={100} setSite={setSite} site={site} />
          <Box my={1} />
        </Features>
        <Features name="PaymentStatusFilter" resolution="disabled">
          <Features name="OpenInvoicesFilter" resolution="enabled">
            <OpenInvoicesFilter />
            <Box my={1} />
          </Features>
        </Features>
        <Features name="PaymentStatusFilter" resolution="enabled">
          <PaymentStatusFilter />
          <Box my={1} />
        </Features>
        <Features name="ShowSupplierFilter">
          <SupplierNameFilterInvoices customerId={customerId} />
          <Box my={1} />
        </Features>
        <Features name="SurchargeServiceOnlyFilter">
          <SurchargesOnlyFilter />
        </Features>
      </Box>
    )
  }
  return (
    <GenericFilterBar data-test-id="finance-filters" toolbarProps={{notEndLast: true}} {...props}>
      <Box width="100%" mt={-1}>
        <Box display="flex" height={57} mt={1} style={{float: 'left'}}>
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="invoices-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
            />
          ) : customerPayers.length !== 1 ? (
            <CustomersFilter onLight />
          ) : null}
        </Box>
        <Box height={57} width={400} mt={1} ml={2} style={{float: 'right'}}>
          <LookupFilter
            hasError={isLookupError}
            payerId={payerId}
            customerId={customerId}
            analyticsId={lookupAnalyticsId}
            userId={userId}
          />
        </Box>
        <Box sx={{display: 'inline-flex', flexWrap: 'wrap'}} mt={1}>
          <Box mb={1}>
            <DateFilter />
          </Box>
          <Box mx={1} />
          <Features name="DestinationSearchByName" resolution="disabled">
            <Features name="ProjectSitesFilter">
              <ProjectsSitesFilter onLight />
            </Features>
          </Features>
          <Features name="DestinationSearchByName">
            <SitesFilter customerId={customerId} limit={100} setSite={setSite} site={site} />
          </Features>
          <Features name="PaymentStatusFilter" resolution="disabled">
            <Features name="OpenInvoicesFilter" resolution="enabled">
              <Box mx={1} />
              <OpenInvoicesFilter />
            </Features>
          </Features>
          <Features name="PaymentStatusFilter" resolution="enabled">
            <Box mx={1} />
            <PaymentStatusFilter />
          </Features>
          <Features name="ShowSupplierFilter">
            <Box mx={1} />
            <SupplierNameFilterInvoices customerId={customerId} />
          </Features>
          <Features name="SurchargeServiceOnlyFilter">
            <Box mx={1} />
            <SurchargesOnlyFilter />
          </Features>
        </Box>
      </Box>
    </GenericFilterBar>
  )
}

export default Filters
