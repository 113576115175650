import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import MultiSelectDropdown from '../../../../Molecules/MultiSelectDropdown'
import {AppState} from '../../../../Root.store'
import {
  removeOpenInvoicesFilter,
  selectOpenInvoicesFilter,
  setOpenInvoicesFilter
} from '../Invoice.filters'
import {InvoiceStatus} from '../Invoice.types'

interface PaymentStatusFilterProps {}
const PaymentStatusFilter: React.FC<PaymentStatusFilterProps> = () => {
  const {t} = useTranslation()
  const includesOpenInvoices = useSelector<
    AppState,
    {status: keyof typeof InvoiceStatus} | undefined
  >((state) => selectOpenInvoicesFilter(state))

  const status = [InvoiceStatus.open, InvoiceStatus.closed, InvoiceStatus.partiallyPaid]
  const dispatch = useDispatch()

  const getPaymentStatusFilters = (selectedList) => {
    selectedList.length === 0 || selectedList.length === status.length
      ? dispatch(removeOpenInvoicesFilter())
      : dispatch(setOpenInvoicesFilter({status: selectedList}))
  }

  return (
    <MultiSelectDropdown
      items={status}
      selectedOptionsList={
        Array.isArray(includesOpenInvoices?.status) ? includesOpenInvoices?.status : []
      }
      onClose={getPaymentStatusFilters}
      dataTestId="payment-status-filter"
      multiSelectLabel={t('invoice.paymentStatus')}
      selectAllLabel={t('filterMenu.allStatus')}
      translationItemPrefix="invoice.paymentStatusFilter"
    />
  )
}

export default PaymentStatusFilter
