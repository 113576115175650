import {Box} from '@material-ui/core'
import {Moment} from 'moment'
import React from 'react'

import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'

import {DateFilter} from './DateFilter'

export interface FilterBarErrorPayments {
  key: string
  label: string
}

export interface PaymentsFilter {
  startDate: string
  endDate: string
  payerId?: string
  skip: number
  limit: number
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
}

interface FilterType {
  startDate: Moment
  endDate: Moment
  handleDates: (filter: {startDate: Moment | null; endDate: Moment | null}) => void
  errors?: FilterBarErrorPayments[]
  otherProps?: React.ReactPropTypes
  xs?: boolean
}

export const Filters: React.FC<FilterType> = ({
  startDate,
  endDate,
  handleDates,
  errors,
  otherProps,
  xs = false
}) => {
  if (xs) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <CustomersFilter onLight />
        <Box my={1} />
        <DateFilter filter={{startDate, endDate}} onDateChange={handleDates} />
      </Box>
    )
  }
  return (
    <GenericFilterBar
      data-test-id="transaction-filters"
      toolbarProps={{notEndLast: true}}
      errors={errors}
      {...otherProps}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex">
          <CustomersFilter onLight />
          <Box mx={1} />
          <DateFilter filter={{startDate, endDate}} onDateChange={handleDates} />
        </Box>
      </Box>
    </GenericFilterBar>
  )
}
